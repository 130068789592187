import { Injectable, OnDestroy } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';

// Services
import { CommonService } from '@app/core/services/common.service';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Environment
import { environment } from '@env/environment';

// Constants
import { SessionStorageKeys } from '@app/shared/constants';

// Types
type PusherChannels = 'proposal' | 'user';


@Injectable({
	providedIn: 'root',
})
export class PusherService implements OnDestroy {
	pusherClient: Pusher;

	readonly channelNames: Record<PusherChannels, string> = {	// key is fixed channel names, value is of string type
		proposal: null,
		user    : null,
	};

	readonly channels: Record<PusherChannels, Channel> = {	// key is fixed channel names, value is of channel type
		proposal     : null,
		user         : null,
	};


	constructor(
		private commonService: CommonService,
		private utils        : Utilities,
	) { }


	setup(): void {
		// Init
		this.pusherClient = new Pusher( environment.pusher.key, {
			cluster  : environment.pusher.cluster,
			// encrypted: true
		} );

		// Channel Subscriptions
		this.channels.proposal      = this.pusherClient.subscribe( this.channelNames.proposal );
		this.channels.user          = this.pusherClient.subscribe( this.channelNames.user );

		// Proposals Channel Event Bindings
		if ( this.channels?.proposal ) {
			this.commonService.setProposalPusherConnected( true );

			this.channels.proposal.bind('update:proposal', (data) => {
				// Proposal has been updated - emit to subscribers
				const proposalId = data['id'];
				if ( proposalId ) {
					this.commonService.proposalDetail( +proposalId );
				}
				this.console( data, 'update:proposal' );
			});

			this.channels.proposal.bind('update:proposal.preview', (data) => {
				// Proposal has been updated - emit to subscribers
				const proposalId = data['id'];
				if ( proposalId ) {
					this.commonService.proposalDetail( +proposalId );
				}
				this.console( data, 'update:proposal.preview' );
			});

			this.channels.proposal.bind('update:proposal.payment', (data) => {
				// Payment on proposal has been updated - emit to subscribers
				const proposalId = data['id'];
				if ( proposalId ) {
					this.commonService.proposalDetail( +proposalId );
				}
				this.console( data, 'update:proposal.payment' );
			});

			this.channels.proposal.bind('delete:proposal', (data) => {
				// Proposal has been deleted - emit to subscribers
				const proposalId = data['id'];
				if ( proposalId ) {
					this.commonService.proposalDetail( +proposalId, true );
				}
				this.console( data, 'delete:proposal' );
			});
		}

		// Users Channel Event Bindings
		if ( this.channels?.user ) {

			this.channels.user.bind('download:file', ( data ) => {
				// Start the download automatically
				const _data      = data['data'];
				const filePath   = _data?.['filePath'];
				const processId  = _data?.['processId'];

				const processIdExists = this.commonService.processIdExists( processId );

				this.console( data, 'download:file' );

				if ( processIdExists && filePath ) {
					// Start the download automatically

					// File path is available - Download the file
					setTimeout(() => {
						// Start file download
						this.commonService.downloadFile( filePath, processIdExists as string );
						this.utils.hideSnackbar();
					}, 1000);

				} else {
					// Do not proceed further as process Id doesn't match for this browser tab - this is to avoid multiple downloads

					// Return will prevent event to be propagated to other UserEventEnum.DownloadPdfFile handlers
					return;
				}
			});

			this.channels.user.bind('download:ready', ( data ) => {
				// File ready for download - Show download button
				const _data      = data['data'];
				const filePath   = _data?.['filePath'];
				const fileType   = _data?.['fileType'];
				const processId  = _data?.['processId'];

				const processIdExists = this.commonService.processIdExists( processId );

				this.console( data, 'download:ready' );

				if ( processIdExists && filePath ) {

					this.utils.showSnackBar( `Your PDF is ready to download.`, 'download', 'success', { duration: -1 } ).onAction().subscribe( () => {
						this.commonService.downloadFile( filePath, processIdExists as string );
					});

				} else {
					// Do not proceed further as process Id doesn't match for this browser tab - this is to avoid multiple downloads

					// Return will prevent event to be propagated to other UserEventEnum.DownloadPdfFile handlers
					return;
				}
			});
		}

	}


	init( proposalId: number, token: string ): void {
		try {

			this.channelNames.proposal = `proposalviewer-${ proposalId }-${ token }-v2`;
			this.channelNames.user     = `users-${ token }`;

			// Destroy if previously exists
			this.ngOnDestroy();

			// Setup SSE instance
			this.setup();

		} catch ( e ) {
			console.log( `Failed to connect to Pusher server`, e );
		}
	}


	public console( data: any, pEvent: any = null ): void {
		if ( !environment.production ) {
			// Only show SSE messages on DEV environments
			if ( pEvent ) {
				console.log( 'PusherEvent: ', pEvent, 'PusherMessage', data );
			} else {
				console.log( 'PusherMessage', data );
			}
		}
	}


	ngOnDestroy() {
		if ( this.channels?.proposal ) {
			this.pusherClient.unsubscribe( this.channelNames.proposal );
			this.channels.proposal.unbind_all();
		}

		if ( this.channels?.user ) {
			this.pusherClient.unsubscribe( this.channelNames.user );
			this.channels.user.unbind_all();
		}

		this.commonService.setProposalPusherConnected( false );
	}

}
