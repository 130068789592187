import { ElementRef, Component, OnInit, OnDestroy, ViewChild, Renderer2, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { differenceInDays } from 'date-fns';

// Services
import { ProposalService } from '@app/core/services/proposal.service';
import { CommonService } from '@app/core/services/common.service';
import { PusherService } from '@app/core/pusher.service';
import { ChangeOrderService } from '@app/proposals/services/change-order.service';
import { ProposalCommonService } from '@app/proposals/services/proposal-common.service';

// Utility
import { Utilities } from '@app/shared/utilities';
import { ImageUtils } from '@app/shared/image-utils';

// Models
import { ErrorModel } from '@app/core/models/error.model';
import {
	IProposalDetailModel,
	IProposalAreaModel,
	IProposalDetailClientModel,
	IProposalAreaItemModel,
	IProposalAreaOptionModel,
	IProposalFinancialsModel,
	IProposalPaymentModel,
	IProposalAcceptedResponse,
	IProposalAreaOptionFinancialsModel,
	IProposalRecurringServicesModel,
	IProposalChangeOrderListModel,
} from '@app/core/models/proposal.models';

// Enums
import { AreaOptionStatusEnum, ContactTypeEnum, ProposalStatusEnum, CloudinaryPathEnum, TransactionStatusEnum, DiscountTypeEnum } from '@app/core/enums';

// Constants
import { ChangeOrderIcons, MaxChangeOrderAvailableColors } from '@app/shared/constants';

// Environment
import { environment } from '@env/environment';


@Component({
	selector   : 'app-proposal-detail',
	templateUrl: './proposal-detail.component.html',
	styleUrls  : ['./proposal-detail.component.scss'],
	providers  : [ ChangeOrderService, ProposalCommonService ],
})
export class ProposalDetailComponent implements OnInit, OnDestroy {
	private readonly pusherService      = inject( PusherService );
	private readonly proposalService    = inject( ProposalService );
	private readonly renderer           = inject( Renderer2 );
	private readonly route              = inject( ActivatedRoute );
	private readonly title              = inject( Title );
	public readonly commonService       = inject( CommonService );
	public readonly changeOrderService  = inject( ChangeOrderService );
	public readonly utils               = inject( Utilities );

	@ViewChild('rightPanel') rightPanel: ElementRef;

	readonly ContactTypeEnum = ContactTypeEnum;

	readonly MaxChangeOrderAvailableColors = MaxChangeOrderAvailableColors;

	proposalId             : number;
	token				   : string;
	clientEmail			   : string;
	clientRef			   : string;
	proposal               : IProposalDetailModel;
	areas                  : IProposalAreaModel[];
	client                 : IProposalDetailClientModel;
	allItems               : IProposalAreaItemModel[] = [];

	clientInfoExists       : boolean = false;

	attachedPayment        : IProposalPaymentModel;
	pendingPayment         : IProposalPaymentModel;
	paymentsToShow		   : IProposalPaymentModel[] = [];

	readonly portalUrl     = environment.portalUrl;

	readonly ProposalStatusEnum    = ProposalStatusEnum;
	readonly CloudinaryPathEnum    = CloudinaryPathEnum;
	readonly DiscountTypeEnum      = DiscountTypeEnum;

	readonly getCompanyLogoImageUrl = ImageUtils.getCompanyLogoImageUrl;
	readonly getCoverLargeImageUrl  = ImageUtils.getCoverLargeImageUrl;

	discountType           : string;
	preview                : string = null;		// Needed for permission
	isPreview              : boolean = false;
	hideTopBar             : boolean = false;
	viewSimpleProposal     : boolean = false;

	areaActiveOptions      = new Map<number, number>();	// Key is areaId, value is optionId - Just active option tab
	areaSelectedOptions    = new Map<number, number>();	// Key is areaId, value is optionId - Option locked in
	unSelectedAreas        : IProposalAreaModel[] = [];

	enableScrollEvents   : boolean = false;
	showChangeOrderNav   : boolean = false;

	clientSettingsUrl   : string;

	highlightCheckboxes: number[] = [];

	readonly loaderStates = {
		page   : false,
		pricing: false,
	};

	readonly showModalStates = {
		acceptProposal     : false,
		pendingOptions     : false,
		clientResponse     : false,
		afterAcceptProposal: false,
	};

	readonly modalRefs = {
		acceptProposal     : null,
		pendingOptions     : null,
		clientResponse     : null,
		afterAcceptProposal: null,
	};

	// Permissions
	viewOnlyPerm = true;

	private totalSub: Subscription;
	private readonly subs = new Subscription();


	get curFormat(): string {
		return this.proposal?.currency?.format;
	}


	get curSymbol(): string {
		return this.proposal?.currency?.symbol;
	}


	get showExpiryDate(): boolean {
		return this.proposal?.expiry?.date && this.proposal?.status !== ProposalStatusEnum.Accepted && this.proposal?.status !== ProposalStatusEnum.Completed;
	}


	get isExpired(): boolean {
		return this.proposal?.status === ProposalStatusEnum.Expired;
	}


	get getPendingPayment(): IProposalPaymentModel {
		return this.proposal?.payments?.find( payment => payment?.status === TransactionStatusEnum.Submitted || payment?.status === TransactionStatusEnum.Viewed );
	}


	get getPaymentsToShow(): IProposalPaymentModel[] {
		return this.proposal?.payments?.filter( payment => payment?.status !== TransactionStatusEnum.Draft );
	}


	get getPaidPaymentsToShow(): IProposalPaymentModel[] {
		return this.proposal?.payments?.filter( payment => payment?.status === TransactionStatusEnum.Paid );
	}


	get getPendingPaymentsToShow(): IProposalPaymentModel[] {
		return this.proposal?.payments?.filter( payment => payment?.status === TransactionStatusEnum.Submitted || payment?.status === TransactionStatusEnum.Viewed )?.filter( payment => !this.checkPastDue( payment ) );
	}


	get getPastDuePaymentsToShow(): IProposalPaymentModel[] {
		return this.proposal?.payments?.filter( payment => payment?.status === TransactionStatusEnum.Submitted || payment?.status === TransactionStatusEnum.Viewed )?.filter( payment => this.checkPastDue( payment ) );
	}


	get isChangeOrder(): boolean {
		return !!this.proposal?.isChangeOrder;
	}


	get isProject(): boolean {
		return this.proposal?.financials?.projectTotal !== this.proposal?.financials?.grandTotal;
	}


	private checkPastDue( paymentRequest: IProposalPaymentModel ): boolean {
		const dueDate      = paymentRequest?.dueDate;
		const daysPassed   = this.numDaysPassed( dueDate );

		return daysPassed < 0 ? true : false;
	}


	private numDaysPassed( comparativeDate: Date ): number {
		if ( !comparativeDate ) { return null; }

		const days = differenceInDays( comparativeDate, new Date() );

		return days;
	}


	ngOnInit() {
		this.loaderStates.page = false;

		// Set HTML title
		this.title.setTitle(`Proposal`);

		// Set Favicon
		this.setFavicon();

		this.subs.add(
			this.route.params.subscribe(params => {
				this.loaderStates.page = true;
				this.proposalId  = +params?.['id'] || null;
				this.token       = params?.['token'] || null;
			})
		);

		let preview = null;
		this.subs.add(
			this.route.queryParams.subscribe( params => {
				this.clientRef = params['ref'] || null;

				// Old url need to get token from query string
				if ( !this.token ) {
					this.token = params['token'] || null;
				}

				preview      = ( params['preview'] ?? params['Preview'] ) || null;
				const source = params['source'] || null;

				this.preview = preview;

				if ( ( source?.toUpperCase() === 'IOS' ) || ( preview?.toUpperCase() !== 'EDIT' && preview?.toUpperCase() !== 'VIEW' ) ) {
					this.hideTopBar = true;
				}

				if ( preview?.toUpperCase() === 'VIEW' ) {
					this.viewOnlyPerm = true;
				} else {
					this.viewOnlyPerm = false;
				}
			})
		);

		// Subscribe to proposalDetail emitted from SSE
		this.subs.add(
			this.commonService.proposalDetail$.subscribe(
				( res: { id: number, deleted: boolean } ) => {
					// User only receives the proposal based on proposalId and token (SSE channel)
					// Only if the received proposal matches with the one user is viewing - update it
					if ( res?.id === this.proposalId ) {
						if ( res?.deleted === true ) {
							// Proposal deleted
							this.proposal = null;

							return;
						}

						this.getProposalDetail( res?.id, this.token, this.clientRef, this.preview );

						this.getChangeOrders( this.proposalId, this.token, this.clientRef, this.preview );
					}
				}
			)
		);

		// Subscribe to change order trigger
		this.subs.add(
			this.changeOrderService.changeTrigger$.pipe( distinctUntilChanged() ).subscribe( () => {
				if ( this.proposal?.areas ) {
					// To show areas which have some change order items
					this.showNonEmptyAreas( this.proposal.areas );
				}
			})
		);

		// Setup Pusher for ServerEvents
		this.setupPusher();

		if ( !this.proposalId ) { this.utils.handleError( 'Proposal Id not found' ); return; }
		if ( !this.token ) { this.utils.handleError( 'Token not found' ); return; }

		// Set component reference in service so parent methods can be used as well
		this.changeOrderService.setComponentRef( this );

		this.getProposalDetail( this.proposalId, this.token, this.clientRef, preview );

		this.getChangeOrders( this.proposalId, this.token, this.clientRef, preview );

		window.addEventListener( 'scroll', this.markNavigationOnScroll, true );

		setTimeout( () => {
			this.enableScrollEvents = true;
			this.markNavigationOnScroll();
		}, 500 );
	}


	private setupPusher(): void {
		// Initialize
		if ( this.proposalId && this.token ) {
			this.pusherService.init( this.proposalId, this.token );
		}
	}


	reloadProposal( withSSE = true, hideLoader = true ): void {
		// If SSE Connected - no need to fetch data from API as SSE will emit it
		if ( this.commonService.getProposalPusherConnected() === true && withSSE ) {
			if ( hideLoader ) {
				this.loaderStates.page = false;
			}
			return;
		} else {
			// Get proposal detail from service
			this.getProposalDetail( this.proposalId, this.token, this.clientRef, this.preview );

			this.getChangeOrders( this.proposalId, this.token, this.clientRef, this.preview );
		}
	}


	ngOnDestroy() {
		this.subs?.unsubscribe();

		this.totalSub?.unsubscribe();

		window?.removeEventListener( 'scroll', this.markNavigationOnScroll, true );

		// Reset to default icon
		this.utils.changeFavicon( null );
	}


	getImageUrl( [ assetId, width, height, crop ]: [ string, number, number, string ] ): string {
		return ImageUtils.getCloudinaryLegacyUrl( assetId, [
			{ width, height, gravity: 'center', crop },
			{ default_image: 'emptyCompany.png' },
		]);
	}


	private populateProposalDetail( proposal: IProposalDetailModel ): void {
		this.proposal           = proposal;

		this.client             = proposal?.client;
		this.discountType       = proposal?.financials?.discountType;
		this.isPreview          = proposal?.isPreview;
		this.allItems           = proposal?.allItems;

		this.pendingPayment     = this.getPendingPayment;
		this.paymentsToShow     = this.getPaymentsToShow;

		this.clientInfoExists   = !this.utils.checkIfObjectEmpty( this.client );

		this.viewSimpleProposal = proposal?.clientSettings?.viewSimpleProposal;

		this.clientSettingsUrl  = `${this.portalUrl}/proposals/${this.proposalId}?clientSettings=true`;

		const allAreas          = proposal.areas;

		this.changeOrderService.setChangeOrderFinancials( proposal?.changeOrderFinancials );

		// Flush map
		this.areaSelectedOptions.clear();

		// Show only areas which have either Description OR part/labor/custom items
		if ( allAreas?.length ) {
			this.showNonEmptyAreas( allAreas );

			// Populate if any options are selected
			this.populateSelectedOptions();

			// Generate list of active options
			for ( const area of this.areas ) {
				this.getAreaActiveOption( area.id );
			}
		}

		// Update Totals based on active options
		this.getActiveTotals();

		// Set HTML title
		this.setTitle();

		// Set Favicon
		this.setFavicon();

		// Hide Global Loader
		this.loaderStates.page = false;
	}


	// Show only areas which have either Description OR part/labor/custom items
	private showNonEmptyAreas( allAreas: IProposalAreaModel[] ): void {
		this.areas = allAreas?.filter( ( area: IProposalAreaModel ) => {
			if ( area?.options ) {
				// Check each option in an area if it contains description (in any of the options) OR items
				for ( const option of area?.options ) {
					if ( option?.items?.length || option?.description ) {
						return true;
					}

					// Also check if change order items exist in any of the options
					const checkedChangeOrdersData = this.changeOrderService.getCheckedChangeOrderData( area?.id, option?.id );

					if ( checkedChangeOrdersData?.length ) {
						for ( const changeOrderData of checkedChangeOrdersData ) {
							if ( changeOrderData?.items?.length ) {
								return true;
							}
						}
					}
				}
			}

			return false;
		});
	}


	private setTitle(): void {
		const proposal = this.proposal;

		if ( proposal ) {
			if ( this.isChangeOrder ) {
				this.title.setTitle( `Change Order ${proposal?.changeOrderMeta?.changeOrderNumber} ${ proposal?.name ? `- ${proposal?.name}` : '' }` );
			} else {
				this.title.setTitle( `Proposal ${proposal.number} ${ proposal?.name ? `- ${proposal?.name}` : '' }`);
			}
		}
	}


	private setFavicon(): void {
		if ( this.proposal ) {
			if ( this.isChangeOrder ) {
				// Grab icon name from ChangeOrderIcons array - 0 index means change order 1
				const iconIndex = Math.min( this.proposal?.changeOrderMeta?.changeOrderNumber - 1, ChangeOrderIcons?.length - 1 );	// Do not go over max array index - select the minium value incase change order is 10 for instance
				const iconName  = ChangeOrderIcons?.[ iconIndex ];

				// Update favicon
				this.utils.changeFavicon( iconName );
			} else {
				// Reset to default icon
				this.utils.changeFavicon( null );
			}
		}
	}


	private populateSelectedOptions(): void {
		this.unSelectedAreas = [];	// Reset array

		// Loop through each area -> option to capture selected options
		if ( this.areas ) {
			for ( const area of this.areas ) {
				if ( area.options ) {
					for ( const option of area.options ) {
						// Each option
						if ( option.status === AreaOptionStatusEnum.Accepted ) {
							this.areaSelectedOptions.set( area.id, option.id );
						}
					}
				}

				if ( !this.isOptionSelected( area.id ) && area?.options?.length > 1 ) {
					// Option exists but is not selected
					this.unSelectedAreas.push( area );
				}
			}
		}
	}


	private getProposalDetail( proposalId: number, securityToken: string, clientRef?: string, preview?: string ): void {
		this.loaderStates.page = true;
		this.subs.add(
			this.proposalService.getDetail( proposalId, securityToken, clientRef, preview ).subscribe(
				( proposal: IProposalDetailModel ) => {
					this.populateProposalDetail( proposal );
				},
				( error: ErrorModel ) => {
					if ( error.code && typeof error.code === 'string' ) {
						if ( error.code.toUpperCase() !== 'UNAUTHORIZEDACCESSEXCEPTION' ) {
							this.utils.handleError( this.commonService.getErrorText( error ) );
						} else {
							// Proposal is no longer authorized for user
							// Make proposal blank
							this.proposal = null;
						}
					} else if ( !error.code ) {
						// Log error/exception to bugsnag
						this.utils.logError( error );
					}

					this.loaderStates.page = false;
				}
			)
		);
	}


	private getChangeOrders( proposalId: number, token: string, clientRef: string, preview: string ): void {
		this.subs.add(
			this.proposalService.getChangeOrders( proposalId, token, clientRef, preview ).subscribe({
				next: ( res ) => {

					this.changeOrderService.setChangeOrders( {
						acceptedChangeOrders: res?.acceptedChangeOrders,
						pendingChangeOrders : res?.pendingChangeOrders,
					} );
				},
				error: ( error: ErrorModel ) => {
					this.handleError( error?.message );
				},
			})
		);
	}


	sortByNumber( changeOrders: IProposalChangeOrderListModel[] ): IProposalChangeOrderListModel[] {
		return changeOrders?.sort(( a, b ) => a?.number - b?.number );	// Create an Ascending List Order and return
	}


	get hasChangeOrders(): boolean {
		return !!this.changeOrderService.hasChangeOrders;
	}


	get allChangeOrders(): IProposalChangeOrderListModel[] {
		return this.changeOrderService.allChangeOrders;
	}


	get showPendingPayment(): boolean {
		return this.proposal?.status === ProposalStatusEnum.Accepted && this.pendingPayment && !this.isPreview;
	}


	isOptionSelected( areaId: number ): boolean {
		return this.areaSelectedOptions.has( areaId );
	}


	private markNavigationOnScroll = (): void => {
		try {

			if ( !this.enableScrollEvents ) {
				return;
			}

			const mainEle = document.querySelector('.scroll-marker');		// Main reference point that is fixed

			if ( !mainEle ) {
				return;
			}

			const topBarHeight     = document.querySelector('.top-bar-client')?.clientHeight || 0;
			const headerHeight     = document.querySelector('.top-bar.client-preview')?.clientHeight || 0;
			const headerFullHeight = headerHeight + topBarHeight;

			const parentTopPos = ( mainEle.getBoundingClientRect()?.top - headerFullHeight );

			// Select all the <a> tags which start with #
			Array.from( document.querySelectorAll('#navigation-links .nav-item') ).forEach( ( item ) => {
				if ( item ) {
					const sectionName          = item.getAttribute('data-section');
					const sectionTriggerOffset = 160; // To activate the section in side nav before it reaches the top
					const offset               = +item.getAttribute('data-offset') || 0;
					const refElement           = document.querySelectorAll('#' + sectionName)[0];
					const childTopPos          = refElement?.getBoundingClientRect()?.top - headerFullHeight;
					const relativeTopPos       = childTopPos - offset - parentTopPos;
					const eleHeight            = refElement?.clientHeight;
					const ceilValue            = Math.ceil( relativeTopPos ) - sectionTriggerOffset;

					if ( ceilValue <= 0 && ceilValue > ( -1 * eleHeight ) ) {
						// Remove all .active classes
						this.removeNavigationActive();

						// Add one .active class
						this.renderer.addClass( item, 'active' );
					}
				}

			});

		} catch ( e ) {
			this.utils.logError( e );
			this.utils.exceptionLog( e );
		}
	};


	private removeNavigationActive(): void {
		// Remove all .active classes
		Array.from( document.querySelectorAll('#navigation-links .nav-item') ).forEach( _item => this.renderer.removeClass(_item, 'active') );
	}


	downloadPdf(): void {
		this.utils.showSnackBar( 'Preparing to Download', 'dismiss', 'loading', { duration: 100000 } ); // Snackbar will get dismissed automatically when the file gets compiled successfully otherwise the default duration is 100 seconds

		this.subs.add(
			this.proposalService.downloadViewerPdf( this.proposalId, this.token, this.highlightCheckboxes ).subscribe({
				next: ( fileFound: boolean ) => {
					this.utils.clearPreviousErrors();

					if ( fileFound ) {
						this.utils.hideSnackbar();
					}
				},
				error: ( error: ErrorModel ) => {
					this.handleError( this.commonService.getErrorText( error ) );
				},
			})
		);
	}


	// Find area with 'areaId'
	private getAreaById( areaId: number ): IProposalAreaModel {
		return this.utils.findItemByKey( this.areas, areaId, 'id');
	}


	private getAreaOptionById( optionId: number, area?: IProposalAreaModel, areaId?: number ): IProposalAreaOptionModel {
		if ( area ) {
			return this.utils.findItemByKey( area.options, optionId, 'id');
		} else {
			const _area = areaId ? this.getAreaById( areaId ) : this.getAreaByOptionId( optionId );
			if ( _area ) {
				return this.utils.findItemByKey( _area.options, optionId, 'id');
			}
		}

		return null;
	}


	private getAreaByOptionId( areaOptionId: number ): IProposalAreaModel {
		return this.areas.find( ( area: IProposalAreaModel ) => area.options.find( ( option: IProposalAreaOptionModel ) => option.id === areaOptionId ));
	}


	onSelectAreaOption( areaId: number, optionId: number ): void {
		this.areaActiveOptions.set( areaId, optionId );

		// Update Financials
		this.getActiveTotals();

		try {
			// Reset scroll position IF area is sticking on top
			if ( document && document.getElementById( 'area-' + areaId ) ) {
				// Top offset relative to window
				const topOffset = document.getElementById( 'area-' + areaId ).getBoundingClientRect().top;
				if ( topOffset < 52 ) {
					this.scrollToSection('area-', areaId, 52);
				}
			}
		} catch ( e ) {
			console.log('Exception: ', e);
		}
	}


	// Get financials of active options
	private getActiveTotals(): void {
		this.loaderStates.pricing = true;
		// If previous request already in progress - cancel it
		if ( this.totalSub ) { this.totalSub.unsubscribe(); }

		const activeOptionIds = [];

		// Gather active option Ids
		this.areaActiveOptions.forEach( ( optionId: number ) => {
			if ( optionId ) {
				activeOptionIds.push( optionId );
			}
		});

		// Fetch total data - value populated by Akita store queries
		this.totalSub = this.proposalService.getProposalTotals( this.proposalId, activeOptionIds ).subscribe(
			( { financials, affectedItems, affectedOptionFinancials, recurringServices }: { financials: IProposalFinancialsModel, affectedItems: IProposalAreaItemModel[], affectedOptionFinancials: { [key: number]: IProposalAreaOptionFinancialsModel }, recurringServices: IProposalRecurringServicesModel } ) => {
				this.loaderStates.pricing = false;

				this.proposal.financials = financials;

				if ( affectedItems?.length ) {
					// Update affected items due to dynamic pricing and area options
					for ( const item of affectedItems ) {
						const index = this.utils.findItemIndexByKey( this.allItems, item?.id, 'id' );

						if ( index !== -1 ) {
							this.allItems[ index ] = item;
						}
					}

					// Do a deep copy to trigger change detection
					this.allItems = this.utils.returnDeepCopy( this.allItems );
				}

				if ( affectedOptionFinancials ) {
					// Update affected options due to dynamic pricing and area options
					for ( const optionId of Object.keys( affectedOptionFinancials ) ) {
						if ( optionId ) {
							const option = this.getAreaOptionById( +optionId );

							if ( option ) {
								option.financials = affectedOptionFinancials[ optionId ];
							}
						}
					}
				}

				this.loaderStates.pricing = false;
			},
			( error: ErrorModel ) => { this.handleError( error.message ); this.loaderStates.pricing = false; }
		);
	}


	getAreaActiveOption( areaId: number ): number {
		if ( !this.areaActiveOptions.has( areaId ) ) {
			// Set default option - Client Accepted or first Draft
			const area = this.getAreaById( areaId );
			if ( area && area.options ) {
				for ( const option of area.options ) {
					if ( option.status === AreaOptionStatusEnum.Accepted ) {
						// Default selection should be accepted option
						this.areaActiveOptions.set( areaId, option.id );
						break;
					} else if ( option.status === AreaOptionStatusEnum.Draft ) {
						// Options are draft - choose the first one
						this.areaActiveOptions.set( areaId, option.id );
						break;
					}
				}
			}
		}

		if ( this.areaActiveOptions.has( areaId ) ) {
			return this.areaActiveOptions.get( areaId );
		}

		return null;
	}


	private handleError( error: string ): void {
		this.utils.handleError( error );
	}


	scrollToSection( sectionId: string, extension: number = null, topOffset: number = 0 ): void {

		if ( extension ) { sectionId = sectionId + extension; }

		const item = document.getElementById(sectionId);

		if ( item ) {
			item.scrollIntoView();
			this.rightPanel.nativeElement.scrollTop -= topOffset;

			const itemDom = document.querySelector('#navigation-links .nav-item[data-section="' + sectionId + '"]');

			if ( itemDom ) {
				this.removeNavigationActive();

				this.renderer.addClass( itemDom, 'active' );
			}
		}
	}


	handleChangeOrdersNav( state: boolean ): void {
		this.showChangeOrderNav = state;

		if ( state ) {
			this.loadHighlightChanges();

			// Initially highlight all accepted change orders checkboxes
			this.highlightCheckboxes = this.changeOrderService?.acceptedChangeOrders.map( i => i?.id );
		} else {
			this.highlightCheckboxes = [];

			this.reloadProposal( null, false );
		}

		this.updateChangeOrderHighlight();
	}


	showPendingOptionsModal(): void {
		setTimeout( () => { this.showModal('pendingOptions'); }, 500 );
	}


	showSignModal(): void {
		setTimeout( () => { this.showModal('acceptProposal'); }, 500 );
	}


	showRequestChangesModal(): void {
		setTimeout( () => { this.showModal('clientResponse'); }, 500 );
	}


	showModal( modalName: string ): void {
		if ( modalName in this.showModalStates ) {
			this.showModalStates[modalName] = true;
		}
	}


	hideModal( modalName: string ): void {
		if ( modalName in this.showModalStates ) {
			// To allow hide animation to play first
			setTimeout( () => { this.showModalStates[modalName] = false; }, 500 );
		}
	}


	onProposalAccept( acceptedResponse: IProposalAcceptedResponse ): void {
		this.attachedPayment = null;

		if ( acceptedResponse?.payment ) {
			// Store payment linked with proposal upon acceptance
			this.attachedPayment = acceptedResponse?.payment;
		}

		setTimeout( () => { this.showModal('afterAcceptProposal'); }, 800 );

		this.reloadProposal();
	}


	// Check if all selected options have been selected
	acceptButton(): void {
		if ( this.unSelectedAreas && this.unSelectedAreas.length ) {
			this.showPendingOptionsModal();
		} else {
			this.showSignModal();
		}
	}


	// Show first unselected area
	showUnselectedArea(): void {
		if ( this.unSelectedAreas?.length ) {
			setTimeout( () => {
				this.scrollToSection( 'area-', this.unSelectedAreas[0]?.id, 52 );
			}, 500 );
		}
	}


	updateChangeOrderCheckbox( value: string, checked: boolean ): void {
		const _value = Number( value );
		const index = this.highlightCheckboxes.indexOf( _value );

		if ( index > -1 && !checked ) {
			this.highlightCheckboxes.splice( index, 1 );
		} else if ( checked ) {
			this.highlightCheckboxes.push( _value );
		}

		this.updateChangeOrderHighlight();
	}


	private updateChangeOrderHighlight(): void {
		this.changeOrderService.updateToShowChangeOrders( this.highlightCheckboxes );
	}


	isHighlightChecked( changeOrderId: number ): boolean {
		return this.highlightCheckboxes.includes( changeOrderId );
	}


	private loadHighlightChanges(): void {
		this.loaderStates.page = true;

		this.subs.add(
			this.proposalService.getChangeOrderHighlights( this.proposalId, this.token, this.clientRef, this.preview ).subscribe({
				next: ( res ) => {
					this.populateProposalDetail( res );
				},
				error: ( error: ErrorModel ) => {
					this.handleError( error?.message );
					this.loaderStates.page = false;
				},
			})
		);
	}
}
