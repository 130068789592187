<div class="main-container" [class.simple-view]="viewSimpleProposal">
	<div class="page-loader-container">
		<app-loader *ngIf="loaderStates?.page" [circle]="true" [style.position]="'fixed'"></app-loader>
	</div>

	<ng-container *ngIf="proposal && ( ( proposal?.status !== ProposalStatusEnum.Declined && !isExpired ) || isPreview === true ) || loaderStates?.page; else proposalElse">
	<header class="top-bar-client" *ngIf="!hideTopBar">
		<div class="top-main position-relative">
			<div class="top-note">This is the web version your client will see</div>
			<div class="settings-container" *ngIf="!viewOnlyPerm && !commonService.isIosWebview"><a class="color-orange" [href]="clientSettingsUrl">View Settings</a></div>
		</div>
	</header>

	<header class="top-bar" [class.client-preview]="!hideTopBar">
		<div class="top-main">
			<div class="header-wrap">
				<div class="top-left-panel" [class.hide-top-logo]="viewSimpleProposal && showPendingPayment">
					<ng-container *ngIf="proposal">
						<div class="logo-container" *ngIf="proposal?.logoImageAssetId">
							<img [src]="[ CloudinaryPathEnum.Company + '/' + proposal?.logoImageAssetId + '.png' ] | pipeFunction: getCompanyLogoImageUrl" class="img-fluid pointer" />
						</div>
						<div class="company-name" *ngIf="!proposal?.logoImageAssetId">
							{{ proposal?.dealer?.companyName | truncate: 22 }}
						</div>
					</ng-container>
				</div>
				<div class="top-right-panel">
					<div *ngIf="!isPreview" class="action-container" [class.d-block]="showPendingPayment">
						<ng-container *ngTemplateOutlet="downloadPdfBtnTmpl" />

						<ng-container *ngIf="proposal?.status === ProposalStatusEnum.Submitted || proposal?.status === ProposalStatusEnum.Viewed">
							<button *ngIf="viewSimpleProposal" type="button" class="_button _secondary-outline f13 fw-bold request-changes-button" (click)="showRequestChangesModal()">Request<app-br class="d-md-none" /> Changes</button>
							<button *ngIf="!viewSimpleProposal" type="button" class="_button _blank request-changes-btn" (click)="showRequestChangesModal()">Request<app-br class="d-md-none" /> Changes</button>&nbsp;&nbsp;
							<button type="button" class="_button _green f13 fw-bold" [class.simple-proposal-accept-button]="viewSimpleProposal" (click)="acceptButton()">Accept <span *ngIf="!viewSimpleProposal" class="d-none d-sm-inline"><ng-container *ngTemplateOutlet="proposalCapitalWordTmpl" /></span></button>
						</ng-container>

						<ng-container *ngTemplateOutlet="proposalStatusPillTmpl" />

						@if ( showPendingPayment ) {
							<!-- Payment due top bar-->
							<div class="payment-due-bar d-none d-md-flex">
								<span class="color-green fw-bold due-text f13"><i class="fa-regular fa-circle-exclamation f14 info-icon"></i>You have a payment due for {{ pendingPayment?.amount | currencyFormat:curFormat:curSymbol }}</span>
								<a [href]="pendingPayment?.paymentUrl" target="_blank" class="_button _green _small _rounded fw-bold _fixed-medium _no-side-padding f12">
									@if ( proposal?.isPaymentProcessingEnabled ) {
										Make a Payment
									} @else {
										View Payment Request
									}
								</a>
							</div>
						}

					</div>

					<div *ngIf="isPreview" class="action-container">
						<ng-container *ngTemplateOutlet="downloadPdfBtnTmpl" />

						<ng-container *ngTemplateOutlet="proposalStatusPillTmpl" />
					</div>

					<ng-template #proposalStatusPillTmpl>
						<div [ngSwitch]="proposal?.status">
							<ng-container *ngSwitchCase="ProposalStatusEnum.Accepted">
								<span class="label-pill _filled _green _big proposal-accepted-pill" [ngClass]="{'d-md-none': pendingPayment?.amount && !isPreview}">
									<span class="action-head fw-bold"><i class="fa-solid fa-check fa-solid check-icon"></i><span class="d-none d-md-inline"><ng-container *ngTemplateOutlet="proposalCapitalWordTmpl" /></span> Accepted</span>
									<span> on <ng-container *ngTemplateOutlet="dateFormatTmpl; context: { dateValue: proposal?.clientAcceptOrDeclineDate }"></ng-container></span>
								</span>
							</ng-container>

							<ng-container *ngSwitchCase="ProposalStatusEnum.Declined">
								<span class="action-head color-red"><span class="d-none d-md-inline"> <ng-container *ngTemplateOutlet="proposalCapitalWordTmpl" /></span> Declined</span>
								<span class="timestamp"><ng-container *ngTemplateOutlet="dateFormatTmpl; context: { dateValue: proposal?.clientAcceptOrDeclineDate }"></ng-container></span>
							</ng-container>

							<ng-container *ngSwitchCase="ProposalStatusEnum.Delayed">
								<span class="action-head color-dark">Changes Required</span>
								<span class="timestamp"><ng-container *ngTemplateOutlet="dateFormatTmpl; context: { dateValue: proposal?.clientAcceptOrDeclineDate }"></ng-container></span>
							</ng-container>

							<ng-container *ngSwitchCase="ProposalStatusEnum.Completed">
								<span class="action-head color-dark">Completed</span>
								<span class="timestamp"><ng-container *ngTemplateOutlet="dateFormatTmpl; context: { dateValue: proposal?.completedDate }"></ng-container></span>
							</ng-container>
						</div>

						<ng-template #dateFormatTmpl let-dateValue="dateValue">
							<ng-container *ngIf="dateValue">{{ dateValue | date:'MMM dd, yyyy' }} ({{ dateValue | date:'hh:mm a' }})</ng-container>
						</ng-template>
					</ng-template>

					<ng-template #downloadPdfBtnTmpl>
						<button *ngIf="viewSimpleProposal && !showPendingPayment" class="_button _secondary-outline f13 fw-semibold _rounded header-pdf-button" (click)="downloadPdf()"><i class="fa-regular fa-file-arrow-down download-icon"></i></button>
					</ng-template>
				</div>
			</div>
		</div>
	</header>

	<main [class.client-preview]="!hideTopBar">
		<aside *ngIf="!viewSimpleProposal && proposal" class="left-panel">
			<section class="left-side-panel" id="navigation-links">

				<div class="navigation-links-list">
					<div class="navigation-links-list-inner">

						<ul class="section-list">
							<li class="nav-item" (click)="scrollToSection('cover-page')" [attr.data-section]="'cover-page'"><h4>Cover Page</h4></li>
							<li class="nav-item" (click)="scrollToSection('about-us')" *ngIf="proposal?.aboutUs" [attr.data-section]="'about-us'"><h4>About Us</h4></li>
							<li class="nav-item" (click)="scrollToSection('project-description')" *ngIf="proposal?.projectDescription" [attr.data-section]="'project-description'"><h4>Project Description</h4></li>
							<li class="nav-item section-list-heading" (click)="scrollToSection('all-areas', null, 1)" [attr.data-section]="'all-areas'"><h4>Areas &amp; Items</h4></li>
						</ul>

						<ul class="section-list indented scrollable">
							<li class="nav-item" *ngFor="let area of areas" title="{{ area?.name }}" (click)="scrollToSection('area-', area?.id, 52)" [attr.data-offset]="'52'" attr.data-section="area-{{ area?.id }}">
								<h4 [class.badge]="!isPreview && !isOptionSelected( area?.id ) && area?.options?.length > 1">{{ area?.name }}</h4>
							</li>
						</ul>

						<ul class="section-list section-list-bottom">
							<li class="nav-item" (click)="scrollToSection('pricing-breakdown')" [attr.data-section]="'pricing-breakdown'"><h4>Financial Summary</h4></li>
							<li class="nav-item" (click)="scrollToSection('change-orders')" *ngIf="hasChangeOrders" [attr.data-section]="'change-orders'"><h4>Change Orders @if ( allChangeOrders?.length ) { <span class="f9 mx-1 pull-up-hard-1">&bull;</span> {{ allChangeOrders?.length }} }</h4></li>
							<li class="nav-item" (click)="scrollToSection('payments')" *ngIf="(proposal?.paymentSchedule?.description || proposal?.paymentSchedule?.items?.length > 0) && !highlightCheckboxes?.length" [attr.data-section]="'payments'"><h4>Payments</h4></li>
							<li class="nav-item" (click)="scrollToSection('project-terms')" *ngIf="proposal?.projectTerms" [attr.data-section]="'project-terms'"><h4>Project Terms</h4></li>
						</ul>

					</div>
				</div>


				<div class="aside-buttons">
					@if ( changeOrderService?.acceptedChangeOrders?.length ) {
						<div class="highlight-change-orders">
							<button *ngIf="!showChangeOrderNav" class="_button _new-lighter-grey f13 fw-bold _rounded _fixed-large _big" (click)="handleChangeOrdersNav( true )">Highlight Changes</button>

							<div *ngIf="showChangeOrderNav" class="change-orders-list-wrapper">
								<span class="close-highlight-icon" (click)="handleChangeOrdersNav( false )"><i class="fa-solid fa-times f16"></i></span>
								<div class="fw-bold f13 text-center color-charcoal">Highlight Changes</div>
								<app-br class="grid-fixed" />
								<app-br class="small-grid-fixed" />

								<div class="change-orders-list">
									@for ( changeOrder of ( changeOrderService?.acceptedChangeOrders | pipeFunction: sortByNumber ) ; track changeOrder?.id ) {
										<label class="input-checkmark rounded-square change-order-number-{{ changeOrder?.number >= MaxChangeOrderAvailableColors ? MaxChangeOrderAvailableColors : changeOrder?.number }}" [class.active]="isHighlightChecked( changeOrder?.id )"> {{ '' // Limit change order number to MaxChangeOrderAvailableColors because of the limited colors }}
											<input type="checkbox" [value]="changeOrder?.id" #changeOrderCheckbox [checked]="isHighlightChecked( changeOrder?.id )" (change)="updateChangeOrderCheckbox( changeOrderCheckbox?.value, changeOrderCheckbox.checked )" />
											<span>Change Order {{ changeOrder?.number }}</span>
										</label>
									}
								</div>
							</div>
						</div>
						<app-br class="grid-fixed" />
						<app-br class="tiny-grid-fixed" />
					}
					<div class="download-pdf">
						<button class="_button _new-lighter-grey download-pdf-button f13 fw-bold _rounded _fixed-large _big" (click)="downloadPdf()"><i class="fa-regular fa-file-pdf file-icon"></i><span>Download PDF</span></button>
					</div>
				</div>

			</section>
		</aside>

		<section class="right-panel" *ngIf="proposal" #rightPanel>
			<div class="scroll-marker"></div>
			<div class="wrap">
				<section id="cover-page">
					<div *ngIf="!viewSimpleProposal; else showSimpleCoverPage" class="view-proposal-info" style="overflow: hidden;">
						<div *ngIf="proposal?.coverImageAssetId">
							<img [src]="[ CloudinaryPathEnum.Company + '/' + proposal?.coverImageAssetId + '.png' ] | pipeFunction: getCoverLargeImageUrl" class="img-fluid" />
						</div>
						<div *ngIf="!proposal?.coverImageAssetId" style="min-height: 478px"></div>

						<section class="proposal-cover-content">

							<div *ngIf="proposal?.name" class="proposal-name">
								<ng-container *hideItBootstrap="['xs']">{{ proposal?.name | truncate:160 }}</ng-container>
								<ng-container *showItBootstrap="['xs']">{{ proposal?.name | truncate:80 }}</ng-container>
							</div>

							<div class="proposal-info">
								<div class="view-proposal-info-section">
									<div class="prepared-text prepared-for uppercase" *ngIf="clientInfoExists">a <ng-container *ngTemplateOutlet="proposalSmallWordTmpl" /> for</div>

									<div class="prepared-for">
										<div *ngIf="client?.displayName" class="client-name">{{ client?.displayName }}</div>
										<div *ngIf="client?.contactType === ContactTypeEnum.Company && client?.name">{{ client?.name }}</div>
										<div *ngIf="client?.contactType === ContactTypeEnum.Person && client?.companyName">{{ client?.companyName }}</div>
										<div *ngIf="client?.email">{{ client?.email }}</div>
										<div *ngIf="client?.phone">{{ client?.phone | phoneFormat }}</div>

										<app-br class="big-grid-fixed" />

										<div *ngIf="client?.street">{{ client?.street }}</div>
										<div *ngIf="client?.suite">{{ client?.suite }}</div>
										<div *ngIf="client?.city || client?.stateAbbreviation || client?.postalCode">{{ client?.city }}<ng-container *ngIf="client?.stateAbbreviation || client?.postalCode">, {{ client?.stateAbbreviation }} {{ client?.postalCode }}</ng-container></div>
									</div>
								</div>

								<div class="view-proposal-info-footer">
									<div class="dealer-block">
										<div class="prepared-text uppercase">Prepared by
											<span *ngIf="proposal?.dealer?.name" class="dealer-name">{{ proposal?.dealer?.name }}</span>
											<span *ngIf="proposal?.dealer?.name && showExpiryDate" class="sep">&#8226;</span>
											<ng-container *ngIf="showExpiryDate">{{ isExpired ? 'Expired' : 'Expires' }} {{ proposal?.expiry?.date | date:'MMMM dd, yyyy' }}</ng-container>
										</div>
									</div>

									<div class="company-container">
										<div class="company-logo-container" *ngIf="proposal?.logoImageAssetId">
											<img [src]="[ CloudinaryPathEnum.Company + '/' + proposal?.logoImageAssetId + '.png' ] | pipeFunction: getCompanyLogoImageUrl" class="img-fluid" style="max-height: 53px; display: inline-block" />

											<app-br class="grid-fixed" />
										</div>

										@if ( !proposal?.clientSettings?.hideCompanyAddress ) {
											<div class="company-info column-2">
												<div class="address-text">
													<div *ngIf="proposal?.dealer?.companyName"><b>{{ proposal?.dealer?.companyName }}</b></div>
													<div *ngIf="proposal?.dealer?.websiteUrl">{{ proposal?.dealer?.websiteUrl }}</div>
													<div *ngIf="proposal?.dealer?.phone">{{ proposal?.dealer?.phone | phoneFormat }}</div>
												</div>
											</div>

											<div class="company-info">
												<div class="address-text">
													<div *ngIf="proposal?.dealer?.street">{{ proposal?.dealer?.street }}</div>
													<div *ngIf="proposal?.dealer?.suite">{{ proposal?.dealer?.suite }}</div>
													<div *ngIf="proposal?.dealer?.city || proposal?.dealer?.stateAbbreviation || proposal?.dealer?.postalCode">{{ proposal?.dealer?.city }}<ng-container *ngIf="proposal?.dealer?.stateAbbreviation || proposal?.dealer?.postalCode">, {{ proposal?.dealer?.stateAbbreviation }} {{ proposal?.dealer?.postalCode }}</ng-container></div>
													<div *ngIf="proposal?.dealer?.license">{{ proposal?.dealer?.license }}</div>
												</div>
											</div>
										}
									</div>

								</div>

							</div>
						</section>
					</div>

					<ng-template #showSimpleCoverPage>
						<app-simple-cover-page
							[proposalStatus]     = "proposal?.status"
							[proposalName]       = "proposal?.name"
							[logoImageAssetId]   = "proposal?.logoImageAssetId"
							[client]             = "proposal?.client"
							[dealer]             = "proposal?.dealer"
							[expiry]             = "proposal?.expiry"
							[hideCompanyAddress] = "proposal?.clientSettings?.hideCompanyAddress"
						/>
					</ng-template>
				</section>

				<app-br *ngIf="!viewSimpleProposal" class="grid" />

				@if ( proposal?.aboutUs && !viewSimpleProposal ) {
					<section id="about-us">
						<header class="section-head">
							<div class="row align-items-center">
								<div class="col-12"><h4 class="std heading">About Us</h4></div>
							</div>
						</header>
						<section class="section no-header no-footer">
							<div class="section-body f13">
								<div class="pre-line description-container" [froalaView]="proposal?.aboutUs"></div>
							</div>
						</section>

					</section>

					<app-br class="grid" />
				}

				@if ( proposal?.projectDescription && !viewSimpleProposal ) {
					<section id="project-description">
						<header class="section-head">
							<div class="row align-items-center">
								<div class="col-12"><h4 class="std heading">Project Description</h4></div>
							</div>
						</header>
						<section class="section no-header no-footer">
							<div class="section-body f13">
								<div class="pre-line description-container" [froalaView]="proposal?.projectDescription"></div>
							</div>
						</section>
					</section>

					<app-br *ngIf="!viewSimpleProposal" class="grid" />
				}

				<section>
					<section id="all-areas"></section>
					<header *ngIf="!viewSimpleProposal" class="section-head">
						<div class="row align-items-center">
							<div class="col-12"><h4 class="std heading">Areas &amp; Items</h4></div>
						</div>
					</header>

					<div class="entire-room">
						<app-proposal-room id="area-{{ area?.id }}"
							*ngFor              = "let area of areas"
							[token]             = "token"
							[area]              = "area"
							[allItems]          = "allItems"
							[isPreview]         = "isPreview"
							[currency]          = "proposal?.currency"
							[activeOptionId]    = "getAreaActiveOption( area?.id )"
							[clientSettings]    = "proposal?.clientSettings"
							[proposalStatus]    = "proposal?.status"
							[isSimpleView]      = "viewSimpleProposal"
							(eventActiveOption) = "onSelectAreaOption( area?.id, $event )"
							(eventReloadParent) = "reloadProposal( false )"
						/>
					</div>

				</section>

				<section id="pricing-breakdown">
					<header *ngIf="!viewSimpleProposal" class="section-head">
						<div class="row align-items-center">
							<div class="col-12"><h4 class="std heading">Financial Summary</h4></div>
						</div>
					</header>
					<!--<section class="section no-header" *ngIf="unSelectedAreas?.length && !isPreview">
						<div class="section-body f13">
							<div class="row color-black">
								<div class="col-12">
									This proposal has options in the following areas:
									<app-br class="grid-fixed" />
									<ul class="unselected-areas-list">
										<li *ngFor="let area of unSelectedAreas"><a (click)="scrollToSection('area-', area?.id, 52)">{{ area?.name | truncate: 50 }}</a></li>
									</ul>
									<app-br class="grid-fixed" />
									Once you select options for each area, your financial summary will be shown.
								</div>
							</div>
						</div>
					</section>-->

					<app-financial-summary
						[loader]             = "loaderStates?.pricing"
						[clientSettings]     = "proposal?.clientSettings"
						[financials]         = "proposal?.financials"
						[currency]           = "proposal?.currency"
						[viewSimpleProposal] = "viewSimpleProposal"
						[isHighlightsShown]  = "!!highlightCheckboxes?.length"
					/>
				</section>

				<app-br [class.grid]="!viewSimpleProposal" [class.big-grid-fixed]="viewSimpleProposal" />

				@if ( hasChangeOrders ) {
					<section id="change-orders" class="change-orders-wrapper">
						<header class="section-head">
							<div class="row align-items-center">
								<div class="col-12"><h4 class="std heading">Change Orders</h4></div>
							</div>
						</header>

						<app-br class="small-grid" />

						@if ( changeOrderService?.pendingChangeOrders?.length ) {
							<section>
								<header class="section-head section-head-label not-sticky">
									<div class="row align-items-center">
										<div class="col-12">
											<h4 class="std heading">
												<div class="col-12">
													<app-subtle-label class="bigger gray">Pending Change Orders</app-subtle-label>
												</div>
											</h4>
										</div>
									</div>
								</header>
								<section class="no-footer no-header">
									@for ( changeOrder of changeOrderService?.pendingChangeOrders; track changeOrder?.id ) {
										<app-change-order-list-item
											[changeOrder] = "changeOrder"
											[currency]    = "proposal?.currency"
										/>
									}
								</section>

								<app-br class="grid" />
							</section>
						}

						@if ( changeOrderService?.acceptedChangeOrders?.length ) {
							<section>
								<header class="section-head section-head-label not-sticky">
									<div class="row align-items-center">
										<div class="col-12">
											<h4 class="std heading">
												<div class="col-12">
													<app-subtle-label class="bigger gray">Accepted Change Orders</app-subtle-label>
												</div>
											</h4>
										</div>
									</div>
								</header>
								<section class="no-footer no-header">
									@for ( changeOrder of changeOrderService?.acceptedChangeOrders; track changeOrder?.id ) {
										<app-change-order-list-item
											[changeOrder] = "changeOrder"
											[currency]    = "proposal?.currency"
										/>
									}
								</section>
							</section>

							<app-br class="small-grid-fixed" />
							<div class="outro-section-total">
								<div class="value-pair f13">
									<div>Accepted Change Order Total</div>
									<div>{{ proposal?.financials?.acceptedChangeOrdersTotal | currencyFormat:curFormat:curSymbol }}</div>
								</div>
								<app-br class="small-grid-fixed" />
								<div class="value-pair f18">
									<div><b>Total With Changes</b></div>
									<div><b>{{ proposal?.financials?.projectTotal | currencyFormat:curFormat:curSymbol }}</b></div>
								</div>
							</div>
						}

						<app-br class="grid-fixed" />
						<app-br class="small-grid-fixed" />
					</section>
				}

				@if ( (proposal?.paymentSchedule?.description || proposal?.paymentSchedule?.items?.length > 0) && !highlightCheckboxes?.length ) {
					<section id="payments">
						<header *ngIf="!viewSimpleProposal" class="section-head">
							<div class="row align-items-center">
								<div class="col-12">
									<h4 class="std heading">Payment Schedule</h4>
								</div>
							</div>
						</header>

						<section class="section no-footer no-header dynamic-section">
							<header *ngIf="viewSimpleProposal">
								<div class="inner-header">
									<h3 class="heading">
										<span class="area-title h5 std no-margin">Payment Schedule</span>
									</h3>
								</div>
							</header>

							<div class="section-body f13">
								@if ( proposal?.paymentSchedule?.description ) {
									<div class="pre-line description-container" [froalaView]="proposal?.paymentSchedule?.description"></div>
								}

								@if ( proposal?.paymentSchedule?.items?.length > 0 ) {
									<br class="grid-fixed" />

									<app-proposal-payment-schedule
										[proposalTotal]     = "proposal?.financials?.grandTotal"
										[projectTotal]      = "proposal?.financials?.projectTotal"
										[isProject]         = "isProject"
										[partsTotal]        = "proposal?.financials?.partsTotal"
										[laborTotal]        = "proposal?.financials?.laborTotal"
										[partsTotalTax]     = "proposal?.financials?.taxDetails?.partsTotalTax"
										[laborTotalTax]     = "proposal?.financials?.taxDetails?.laborTotalTax"
										[scheduledPayments] = "proposal?.paymentSchedule?.items"
										[isSimpleView]      = "viewSimpleProposal"
										[isChangeOrder]     = "proposal?.isChangeOrder"
									/>
								}
							</div>
						</section>
					</section>

					<app-br [class.grid]="!viewSimpleProposal" [class.big-grid-fixed]="viewSimpleProposal" />
				}

				@if ( ( getPastDuePaymentsToShow?.length || getPendingPaymentsToShow?.length || getPaidPaymentsToShow?.length ) && !highlightCheckboxes?.length ) {
					<section id="payment-requests" class="payment-requests-wrapper">
						<header class="section-head">
							<div class="row align-items-center">
								<div class="col-12">
									<h4 class="std heading">Payment Requests</h4>
								</div>
							</div>
						</header>
						<app-br class="small-grid" />

						@if ( getPastDuePaymentsToShow?.length ) {
							<header class="section-head section-head-label not-sticky">
								<div class="row align-items-center">
									<div class="col-12">
										<h4 class="std heading">
											<div class="col-12">
												<app-subtle-label class="bigger gray">Past Due Requests</app-subtle-label>
											</div>
										</h4>
									</div>
								</div>
							</header>

							<section class="no-footer no-header">
								<app-payment-request *ngFor="let payment of getPastDuePaymentsToShow"
									[payment]                    = "payment"
									[isPaymentProcessingEnabled] = "proposal?.isPaymentProcessingEnabled"
									[currency]                   = "proposal?.currency"
								/>
							</section>
						}

						@if ( getPendingPaymentsToShow?.length ) {
							<header class="section-head section-head-label not-sticky">
								<div class="row align-items-center">
									<div class="col-12">
										<h4 class="std heading">
											<div class="col-12">
												<app-subtle-label class="bigger gray">Pending Requests</app-subtle-label>
											</div>
										</h4>
									</div>
								</div>
							</header>

							<section class="no-footer no-header">
								<app-payment-request *ngFor="let payment of getPendingPaymentsToShow"
									[payment]                    = "payment"
									[isPaymentProcessingEnabled] = "proposal?.isPaymentProcessingEnabled"
									[currency]                   = "proposal?.currency"
								/>
							</section>
						}

						@if ( getPaidPaymentsToShow?.length ) {
							<header class="section-head section-head-label not-sticky">
								<div class="row align-items-center">
									<div class="col-12">
										<h4 class="std heading">
											<div class="col-12">
												<app-subtle-label class="bigger gray">Paid Requests</app-subtle-label>
											</div>
										</h4>
									</div>
								</div>
							</header>

							<section class="no-footer no-header">
								<app-payment-request *ngFor="let payment of getPaidPaymentsToShow"
									[payment]                    = "payment"
									[isPaymentProcessingEnabled] = "proposal?.isPaymentProcessingEnabled"
									[currency]                   = "proposal?.currency"
								/>
							</section>

							<app-br class="small-grid-fixed" />
							<div class="outro-section-total">
								<div class="value-pair f13" *ngIf="utils.isValueDefined( proposal?.financials?.paidPaymentRequestTotal )">
									<div>Paid Request Total</div>
									<div>{{ proposal?.financials?.paidPaymentRequestTotal | currencyFormat:curFormat:curSymbol }}</div>
								</div>
								<app-br class="small-grid-fixed" />
								<div class="value-pair f18" *ngIf="utils.isValueDefined( proposal?.financials?.projectBalance )">
									<div><b>Balance</b></div>
									<div><b>{{ proposal?.financials?.projectBalance | currencyFormat:curFormat:curSymbol }}</b></div>
								</div>
							</div>
						}
					</section>

					<app-br class="grid" />
				}

				@if ( proposal?.projectTerms ) {
					<section id="project-terms">
						<header *ngIf="!viewSimpleProposal" class="section-head">
							<div class="row align-items-center">
								<div class="col-12"><h4 class="std heading">Project Terms</h4></div>
							</div>
						</header>

						<section class="section no-header no-footer dynamic-section">
							<header *ngIf="viewSimpleProposal">
								<div class="inner-header">
									<h3 class="heading">
										<span class="area-title h5 std no-margin">Project Terms</span>
									</h3>
								</div>
							</header>
							<div class="section-body f13">
								<div class="pre-line description-container" [froalaView]="proposal?.projectTerms"></div>
							</div>
						</section>
					</section>

					<app-br class="grid" />
				}
			</div>

			<app-br class="big-grid" />

		</section>

	</main>

	</ng-container>

	<ng-template #proposalElse>
		<section *ngIf="isPreview === false" class="no-proposal declined text-center">

			@if ( isExpired ) {
				<div class="not-available-section">
					<i class="fa-duotone fa-hourglass-clock icon"></i>

					<h1 class="not-available-heading">
						This <ng-container *ngTemplateOutlet="proposalSmallWordTmpl" /> expired<br />
						<ng-container *ngIf="proposal?.expiry?.date">on {{ proposal?.expiry?.date | date:'MMMM dd, yyyy' }}</ng-container>
					</h1>
				</div>
			} @else {
				<div class="not-available-section">
					<i class="fa-duotone fa-times-circle error-icon"></i>

					<h1 class="not-available-heading">Oops we couldn't find<br />what you are looking for</h1>
				</div>
			}

			<div class="dealer-card" *ngIf="proposal?.name || proposal?.dealer?.phone || proposal?.dealer?.email">
				<div class="dealer-data">
					<div *ngIf="proposal?.dealer?.name" class="name">{{ proposal?.dealer?.name }}</div>
					<div *ngIf="proposal?.dealer?.companyName" class="company-name">{{ proposal?.dealer?.companyName }}</div>
					<ul>
						<li *ngIf="proposal?.dealer?.phone">{{ proposal?.dealer?.phone | phoneFormat }}</li>
						<li *ngIf="proposal?.dealer?.phone && proposal?.dealer?.email" class="separator"></li>
						<li *ngIf="proposal?.dealer?.email">{{ proposal?.dealer?.email }}</li>
					</ul>
				</div>
			</div>
		</section>
	</ng-template>

</div>


<!-- Accept Proposal box -->
<app-accept-proposal-modal
	*ngIf               = "showModalStates?.acceptProposal"
	[proposalId]        = "proposalId"
	[token]             = "token"
	[clientEmail]       = "clientEmail"
	[clientRef]         = "clientRef"
	(eventCloseModal)   = "hideModal('acceptProposal')"
	(eventModalRef)     = "modalRefs.acceptProposal = $event"
	(eventAccepted)     = "onProposalAccept( $event )"
/>

<!-- Pending Option selection box -->
<app-pending-options-modal
	*ngIf             = "showModalStates?.pendingOptions"
	[unSelectedAreas] = "unSelectedAreas"
	[isSimpleView]    = "viewSimpleProposal"
	(eventCloseModal) = "hideModal('pendingOptions')"
	(eventModalRef)   = "modalRefs.pendingOptions = $event"
	(eventOkayButton) = "showUnselectedArea()"
/>

<!-- Request changes Proposal box -->
<app-request-changes-modal
	*ngIf               = "showModalStates?.clientResponse"
	[proposalId]        = "proposalId"
	[token]             = "token"
	[salesPerson]       = "proposal?.dealer?.name"
	[clientEmail]       = "clientEmail"
	(eventCloseModal)   = "hideModal('clientResponse')"
	(eventModalRef)     = "modalRefs.clientResponse    = $event"
	(eventReloadParent) = "reloadProposal()"
/>

<!-- After acceptance Proposal box -->
<app-after-accept-proposal-modal
	*ngIf                        = "showModalStates?.afterAcceptProposal"
	[proposalId]                 = "proposalId"
	[isPaymentProcessingEnabled] = "proposal?.isPaymentProcessingEnabled"
	[token]                      = "token"
	[salesPerson]                = "proposal?.dealer?.name"
	[payment]                    = "attachedPayment"
	[currency]                   = "proposal?.currency"
	(eventCloseModal)            = "hideModal('afterAcceptProposal')"
	(eventModalRef)              = "modalRefs.afterAcceptProposal = $event"
	(eventReloadParent)          = "reloadProposal()"
/>


<ng-template #proposalCapitalWordTmpl>
	@if ( !isChangeOrder ) {<!-- -->Proposal<!-- -->} @else {<!-- -->Change Order<!-- -->}
</ng-template>

<ng-template #proposalSmallWordTmpl>
	@if ( !isChangeOrder ) {<!-- -->proposal<!-- -->} @else {<!-- -->change order<!-- -->}
</ng-template>
